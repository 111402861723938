<template>
  <div class="upload_box">
    <LEFTNAV></LEFTNAV>
    <div class="box">
      <div class="box_part" v-bind:style="{ minHeight: Height - 40 + 'px' }">
        <div class="fil_view">
          <div class="title">
            <h2 style="text-align: center; font-size: 25px; line-height: 80px">
              scholarcy 文件上传
            </h2>
          </div>
          <div class="prompts">
            <div class="prompt">
              <el-steps :active="3">
                <el-step title="上传文件" icon="el-icon-upload"></el-step>
                <el-step
                  title="进行检测"
                  icon="el-icon-data-analysis"
                ></el-step>
                <el-step title="生成报告" icon="el-icon-tickets"></el-step>
              </el-steps>
              <div class="note_fu">
                <div class="note_fu01">
                  <el-divider content-position="left">功能介绍</el-divider>
                  <div class="note">
                    
                    <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span
                        >Scholarcy致力于为科研人员与学者提供智能阅读服务，帮助科研人员与学者在工作的各个阶段减少非必要的文献阅读时间，实现精炼阅读，大幅提升工作阅读效率。</span
                      >
                    </p>
                    <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span
                        >Scholarcy通过先进的Robot-Highighter
                        <i class="top">TM</i>
                        技术从文档中提取结构化数据并同步翻译成中文并通过知识归纳引擎生成文章概要，精炼地呈现文章。</span
                      >
                    </p>
                    <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span
                        >Scholarcy提炼要点全面包括摘要、关键点、概要、简介、研究方法、研究结果、讨论、相关工作、总结、基金、参与人员与数据统计、参考文献、笔记备注等内容从而大幅节省科研时间，降低跨界阅读门槛、全面梳理研究脉络、适合可重复性研究。</span
                      >
                    </p>
                    <!-- <p>
                      <img src="../../assets/imgs/yellow.png" alt="" />
                      <span
                        >在线浏览文档翻译结果若出现问题，可通过“问题反馈”将您遇到的错误/问题进行上传。</span
                      >
                    </p> -->
                  </div>
                </div>
              </div>

              <!-- <img style="width: 100%" src="../../../assets/imgs/上传.png" alt="" /> -->
            </div>
          </div>

          <div class="upLoad">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              name="file"
              :data="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              id="uploadForm"
            >
              <!-- <el-form-item label="文章标题" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="文章作者" prop="author">
            <el-input v-model="ruleForm.author"></el-input>
          </el-form-item>
          <el-form-item label="检测类型" prop="task_type">
            <el-radio-group v-model="ruleForm.task_type">
              <el-radio :label="0">英文查重</el-radio>
              <el-radio :label="1">语法校对</el-radio>
            </el-radio-group>
          </el-form-item> -->
              <!-- <el-form-item label="文件上传" prop="file"> -->
              <div class="usages">
                <div class="usage">
                  是否需要翻译：
                  <el-radio v-model="radio" label="1">是</el-radio>
                  <el-radio v-model="radio" label="0">否</el-radio>
                </div>
                <!-- <div class="usage">当日剩余使用量：{{ usages }}</div> -->
              </div>

              <div class="upDemo" v-if="isRouterAlive">
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <div>
                    <el-upload
                      class="upload-demo"
                      id="uploadForm"
                      drag
                      :action="actionUrl"
                      :headers="{ token: token }"
                      :before-upload="beforeUpload"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :on-change="on_change"
                      :before-remove="beforeRemove"
                      multiple
                      :limit="1"
                      :on-exceed="handleExceed"
                      :auto-upload="false"
                      :file-list="fileList"
                      ref="upload"
                      :show-file-list="false"
                    >
                      <div class="upStyle">
                        <!-- margin: 0 0 50px 50px -->
                        <div style="">
                          <div style="margin: 30px 0 0px 0px">
                            <i
                              class="el-icon-upload"
                              style="
                                font-size: 67px;
                                color: #c0c4cc;
                                margin: 40px 0 16px;
                                line-height: 50px;
                              "
                            ></i>
                            <div class="el-upload__text">
                              <em style="font-style: normal">点击上传</em>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">只能上传txt/docx/pdf文件</div> -->
                    </el-upload>
                    <div
                      style="
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                      "
                    >
                      <div class="lists">
                        <div
                          v-for="(item, index) in upList"
                          :key="index"
                          class="lis"
                        >
                          <el-popconfirm
                            title="确定删除吗？"
                            @confirm="delet(item, index)"
                          >
                            <i
                              slot="reference"
                              class="el-icon-circle-close"
                            ></i>
                          </el-popconfirm>

                          <img src="../../assets/imgs/pdf (1).png" alt="" />
                          <p>{{ item.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="upload_tip">
                    <img
                      class="upload_tip_img"
                      src="../../assets/imgs/tip.307c8b1.png"
                      alt=""
                    />
                    <div class="upload_tip_text">
                      <p class="upload_tip_p">
                        仅支持英语期刊原创论文（Articles）的切分提取翻译。
                      </p>
                      <!-- <p class="upload_tip_p">
                    文档大小必须小于10万字符， 4 MB大小
                  </p> -->

                      <p class="upload_tip_p">文档大小必须小于 30MB。</p>
                      <p class="upload_tip_p">
                        文件上传仅限于支持pdf格式文件。
                      </p>
                      <p class="upload_tip_p">
                        上传扫描版PDF将会影响您的使用体验。
                      </p>

                      <!-- <p class="upload_tip_p">
                      PC端程序暂不支持该功能。
                      <br />
                    </p> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- </el-form-item>
          <el-form-item> -->
              <div class="btn">
                <el-button
                  type="primary"
                  @click="submitForm()"
                  :loading="returns"
                  >提&emsp;交</el-button
                >
              </div>
              <!-- </el-form-item> -->
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="系统检测提示"
      :visible.sync="dialogVisible"
      :width="'500px'"
      :before-close="handleClose"
    >
      <!-- <div class="prompt">
        <div class='text'>

        </div>
    </div> -->
      <p class="scans" v-if="scanList.length > 0">
        <span class="tit">温馨提示一：</span> 系统检测到您上传的文件中，
        <span class="files" v-for="(item, index) in scanList" :key="index"
          >"{{ item.file_name }}"</span
        >
        为扫描版文件，可能无法正常生成报告！
      </p>
      <p class="scans" v-if="scanList01.length > 0">
        <span class="tit">温馨提示二：</span> 系统检测到您上传的文件中，
        <span class="files" v-for="(item, index) in scanList01" :key="index"
          >"{{ item.file_name }}"</span
        >
        中文占比超过50%，本平台适用于外文检测，可能无法正常生成报告！
      </p>
      <p class="sure">您确定要继续上传吗？</p>
      <!-- <span>这是一段信息</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureUpload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LEFTNAV from "./component/leftNav.vue";
import $ from "jquery";
import { Message } from "element-ui";
import {
  detail_list,
  input_file,
  download,
  get_limit,
  judge_file,
} from "@/Api/scholarcy_writeful";
export default {
  data() {
    return {
      Height: 0,
      actionUrl: "https://scholary.newacademic.net/api/qingli/sch/input_file",
      token: localStorage.getItem("token"),
      ruleForm: {
        title: "",
        author: "",
        task_type: null,
        file: "",
      },
      rules: {
        // title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        // author: [
        //   { required: true, message: "请输入文章作者", trigger: "blur" },
        // ],
        // task_type: [
        //   { required: true, message: "请选择检测类型", trigger: "change" },
        // ],
        file: [
          { required: true, message: "请选择文件上传", trigger: "change" },
        ],
      },
      fileList: [],
      checkAgain: false,
      detects: true,
      isRouterAlive: true,
      returns: false,
      usages: 0,
      radio: "1",
      upList: [],
      dialogVisible: false,
      scanList: "",
      scanList01: "",
      
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 140;
    };
    // this.getUsage();
  },
  created() {},
  methods: {
    getUsage() {
      let limit_ids = {
        open_id: localStorage.getItem("code"),
      };
      get_limit(limit_ids)
        .then((res) => {
          console.log(res);
          this.usages = res.data.data;
        })
        .catch((err) => {
          //   console.log(err);
        });
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    // 上传前处理
    beforeUpload(file, fileList) {
      console.log(file, fileList);
      // let fileSize = file.size;
      // const FIVE_M = 5 * 1024 * 1024;
      // //大于5M，不允许上传
      // if (fileSize > FIVE_M) {
      //   this.$message.error("最大上传5M");
      //   return false;
      // }
      //判断文件类型，必须是xlsx格式
      // let fileName = file.name;
      // let reg = /^.+(\.xlsx)$/;
      // if (!reg.test(fileName)) {
      //   this.$message.error("只能上传xlsx!");
      //   return false;
      // }
      // return true;
    },
    on_change(file, fileList) {
      this.fileList = [];
      this.upList = [];
      console.log(file, fileList);
      let fileName = file.name;
      let reg = /^.+(\.pdf)$/;
      let reg01 = /^.+(\.zip)$/;
      //   return
      //   for (let i = 0; i < fileList.length - 1; i += 1) {
      //     for (let j = 0; j < fileList.length; j += 1) {
      //       if (fileList[i].name === fileList[j].name) {
      //         fileList.splice(i, 1);
      //         break;
      //       }
      //     }
      //   }
      fileList.forEach((item, index) => {
        let fileName01 = item.name;
        if (!reg.test(fileName01) && !reg01.test(fileName01)) {
          this.$message.error("只能上传pdf格式文件!");
        } else {
          this.fileList.push(item);
          this.upList.push(item);
        }
      });
      return;
      if (!reg.test(fileName) && !reg01.test(fileName)) {
        this.$message.error("只能上传pdf格式文件!");
        this.fileList = [];
        fileList = [];
        this.upList = [];
        return;
      } else {
        this.ruleForm.file = file;
        this.fileList = fileList;
        this.upList = fileList;
      }
      //   console.log(file, fileList);
      //   console.log(this.upList);
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    reloadProof() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    verify() {
      this.scanList01 = [];
      this.scanList = [];
      var formData01 = new FormData();
      this.fileList.forEach((file) => {
        formData01.append("input_file", file.raw);
      });
      formData01.append("organ_name", localStorage.getItem("org_name"));
      let that = this;
      $.ajax({
        url: "https://scholary.newacademic.net/api/qingli/sch/judge_file",
        type: "POST",
        cache: false,
        data: formData01,
        processData: false,
        contentType: false,
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          token: localStorage.getItem("token"),
          // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
        },
      })
        .done(function (res) {
          console.log(res);
          //    let that_ = this;
          if (res.data.length == 0) {
            that.returns = true;
            if (that.fileList.length > 0) {
              var formData = new FormData();
              that.fileList.forEach((file, index) => {
                //   console.log(that.fileList[index].raw)
                formData.append("input_file", that.fileList[index].raw);
                formData.append("filename", that.fileList[index].raw.name);
                if (that.radio == "1") {
                  formData.append("is_tran", 1);
                } else {
                  formData.append("is_tran", 0);
                }

                formData.append("open_id", localStorage.getItem("code"));
                formData.append("organ_name", localStorage.getItem("org_name"));
                // return
                $.ajax({
                  url: "https://scholary.newacademic.net/api/qingli/sch/input_file",
                  type: "POST",
                  cache: false,
                  data: formData,
                  processData: false,
                  contentType: false,
                  headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    token: localStorage.getItem("token"),
                    // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
                  },
                })
                  .done(function (res) {
                    console.log(res);
                    //   this.$refs.upload.clearFiles();
                    // let that = this;

                    if (res.code == 200) {
                      that.fileList = [];
                      that.upList = [];
                      Message({
                        message:
                          res.message +
                          ",请在  查看报告  中浏览! 剩余使用量：" +
                          res.data_count.num,
                        type: "success",
                      });
                      // this.fileList = [];
                    } else {
                      Message.error(res.message);
                      this.returns = false;
                    }
                    // this.returns = false;
                  })
                  .fail(function (res) {
                    Message.error("提交失败！！请稍后再试！");
                    // this.returns = false;
                  });
                //   this.reloadProof();
              });
              setTimeout(() => {
                that.returns = false;
              }, 1000);
              //   formData.append("input_file", this.fileList[0].raw);
            } else {
              var formData = new FormData($("#uploadForm")[0]);
            }
          } else {
            that.dialogVisible = true;
            res.data.forEach((item, index) => {
              if (item.is_message == 2) {
                that.scanList01.push(item);
              } else {
                that.scanList.push(item);
              }
            });
          }

          // this.returns = false;
        })
        .fail(function (res) {
          Message.error("提交失败！！请稍后再试！");
          // this.returns = false;
        });
    },
    handleClose(done) {},
    // 确认上传
    submitForm(formName) {
      // console.log(this.fileList)
      // return
      // console.log("aa")
      // var formData = new FormData($("#uploadForm")[0]);
      // var formData = new FormData($("#uploadForm")[0]);
      // console.log(formData)
      // this.$refs.uploadFile2是vue中获取dom元素的方法
      // 通过files就可以拿到所有上传的文件，如果是多个文件循环即可
      // formData.append("file", this.$refs.uploadFile2.files[0]);
      // 必须设置请求类型
      // formData.append("title", this.ruleForm.title);
      // 如果需要传id的情况下，参考下面代码
      // formData.append("author", this.ruleForm.author);
      if (this.fileList.length > 0) {
        this.verify();
        return;
        this.returns = true;
        if (this.fileList.length > 0) {
          var formData = new FormData();
          this.fileList.forEach((file) => {
            formData.append("input_file", file.raw);
          });
          //   formData.append("input_file", this.fileList[0].raw);
        } else {
          var formData = new FormData($("#uploadForm")[0]);
        }

        if (this.radio == "1") {
          formData.append("is_tran", 1);
        } else {
          formData.append("is_tran", 0);
        }

        formData.append("open_id", localStorage.getItem("code"));
        formData.append("organ_name", localStorage.getItem("org_name"));
        // console.log(this.ruleForm);
        // checks(formData)
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        // var formData = this.ruleForm;

        $.ajax({
          url: "https://scholary.newacademic.net/api/qingli/sch/input_file",
          type: "POST",
          cache: false,
          data: formData,
          processData: false,
          contentType: false,
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
            token: localStorage.getItem("token"),
            // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
          },
        })
          .done(function (res) {
            console.log(res);
            //   this.$refs.upload.clearFiles();
            let that = this;
            that.fileList = [];

            if (res.code == 200) {
              Message({
                message: res.message + ",请在  查看报告  中浏览",
                type: "success",
              });
              // this.fileList = [];
            } else {
              Message.error(res.message);
              this.returns = false;
            }
            // this.returns = false;
          })
          .fail(function (res) {
            Message.error("提交失败！！请稍后再试！");
            // this.returns = false;
          });
        //   this.reloadProof();
        setTimeout(() => {
          this.returns = false;
        }, 1000);
      } else {
        this.$message({
          message: "请先上传文件!",
          type: "warning",
        });
      }

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     // console.log(this.ruleForm);

      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    sureUpload() {
      let that = this;
      that.returns = true;
      if (that.fileList.length > 0) {
        var formData = new FormData();
        that.fileList.forEach((file, index) => {
          //   formData.append("input_file", file.raw);
          formData.append("input_file", that.fileList[index].raw);
          formData.append("filename", that.fileList[index].raw.name);
          if (that.radio == "1") {
            formData.append("is_tran", 1);
          } else {
            formData.append("is_tran", 0);
          }

          formData.append("open_id", localStorage.getItem("code"));
          formData.append("organ_name", localStorage.getItem("org_name"));
          $.ajax({
            url: "https://scholary.newacademic.net/api/qingli/sch/input_file",
            type: "POST",
            cache: false,
            data: formData,
            processData: false,
            contentType: false,
            headers: {
              // "Content-Type": "application/x-www-form-urlencoded",
              token: localStorage.getItem("token"),
              // Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE5NzQ4OTAsImlhdCI6MTY0MTM3MDA4MCwiZGF0YSI6eyJpZCI6MTY0LCJlbWFpbCI6IjEwMTM3MTU0NzNAcXEuY29tIiwicGhvbmUiOiIxODR4eHh4MDYwNyIsIm5hbWUiOiJcdTVmOTBcdTU2MDlcdTY1NGYiLCJmYWN1bHR5IjoiXHU1ZjAwXHU1M2QxIiwic3RhdHVzIjowfX0.629NEWBqMr2-unocUDSScxUQAhr0G3HgitzdnM4hw7E",
            },
          })
            .done(function (res) {
              console.log(res);
              //   this.$refs.upload.clearFiles();
              // let that = this;

              that.dialogVisible = false;
              if (res.code == 200) {
                that.fileList = [];
                that.upList = [];
                Message({
                  message:
                    res.message +
                    ",请在  查看报告  中浏览! 剩余使用量：" +
                    res.data_count.num,
                  type: "success",
                });
                // this.fileList = [];
              } else {
                Message.error(res.message);
                this.returns = false;
              }
              // this.returns = false;
            })
            .fail(function (res) {
              Message.error("提交失败！！请稍后再试！");
              // this.returns = false;
            });
          //   this.reloadProof();
        });
        setTimeout(() => {
          this.returns = false;
        }, 1000);
        //   formData.append("input_file", this.fileList[0].raw);
      } else {
        var formData = new FormData($("#uploadForm")[0]);
      }
    },
    delet(row, index) {
      this.fileList.splice(index, 1);
      this.upList.splice(index, 1);
    },
  },
  components: {
    LEFTNAV,
  },
};
</script>

<style lang="less" scoped>
.upload_box {
  padding-top: 75px;
  min-width: 1360px;
  display: flex;

  color: #000000;
  .box {
    margin: 20px;
    background: white;
    width: 100%;
    .box_part {
      //   background: white;
      width: 100%;
      padding: 20px;
    }
  }
}
.upLoad {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  .el-input {
    width: 360px;
  }
  .btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .el-button {
      width: 120px;
      font-size: 16px;
    }
  }
  .upDemo {
    width: calc(100% - 100px);
    // min-width: 920px;
    // height: 300px;

    margin: 0 45px;
    border: 1px rgb(139, 139, 139) dashed;
    border-radius: 5px;
    .lists {
      //   width: 80%;
      margin: 0 20px 10px 20px;
      position: relative;
      top: -20px;
      min-height: 72px;
      //   height: 30px;
      //   background: #666;
      display: flex;
      flex-wrap: wrap;
      .lis {
        margin-right: 10px;
        width: 60px;
        i {
          position: relative;
          top: 10px;
          left: 45px;
          z-index: 100;
        }
        i:hover {
          color: #2496e2;
          cursor: pointer;
        }
        img {
          width: 36px;
          margin: 0 12px;
        }
        p {
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .upload-demo {
      // width: 920px;
      // margin: 0 40px;
      /deep/ .el-upload {
        // width: 100%;
        .el-upload-dragger {
          // width: 100%;
          // height: 300px;
          border: none;
          .upStyle {
            // display: flex;
            // justify-content: space-between;
          }
        }
      }
    }
  }
}
.prompts {
  display: flex;
  justify-content: center;
}
.prompt {
  // min-width: 1100px;
  width: 90%;
  max-width: 1200px;
  // margin: 0 20%;
  .el-steps {
    margin: 0px 50px;
    color: goldenrod !important;
    /deep/ .el-step__head {
      color: #409eff;
      border-color: #409eff;
    }
    /deep/ .el-step__title {
      color: #409eff;
      font-size: 20px;
      position: relative;
      left: -20px;
    }
    /deep/ .el-step__icon-inner {
      font-size: 30px;
      margin: 0 5px;
    }
  }
  .prompt_text {
    margin: 5px 50px;
    display: flex;
    .arrow {
      display: inline-block;
      width: 80px;
      height: 40px;
      line-height: 32px;
      text-align: center;
      border: 3px solid rgb(207, 207, 207);
      border-right: none;
      color: rgb(107, 107, 107);
      font-size: 18px;
    }
    i {
      display: inline-block;
      font-size: 55px;
      position: relative;
      top: -7px;
      left: -20px;
      font-weight: lighter;
      color: rgb(207, 207, 207);
    }
    .text {
      line-height: 40px;
      display: inline-block;
      font-size: 17px;
    }
  }
}
.note_fu {
  display: flex;
  justify-content: center;
  margin: 20px 110px 20px 80px;
  .note_fu01 {
    width: 100%;
    // max-width: 1070px;
    // margin: 30px auto !important;
  }
}
/deep/ .el-divider {
  // min-width: 800px;
  margin: 0 !important;
  //   width: 90% !important;
}
.note {
  //   width: 90%;
  //    min-width: 800px;
  padding: 10px 30px;
  border-left: 1px #dcdfe6 solid;
  border-right: 1px #dcdfe6 solid;
  border-bottom: 1px #dcdfe6 solid;
  p {
    line-height: 25px;
    display: flex;
    margin: 10px 0;
    // min-width: 1000px;
    img {
      margin: 0 20px;
      width: 12px;
      height: 12px;
      //   position: relative;
      //   top: 8px;
    }
    span {
      word-break: normal;
      //   width: 500px;
      display: block;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
      .top {
        font-size: 5px;
        position: relative;
        top: -8px;
        left: -3px;
        font-style: normal;
      }
    }
  }
}
.upload_tip {
  position: relative;
  margin: 40px 0 0px 0;
  background-color: #f0f7fd;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .upload_tip_img {
    position: absolute;
    left: 50%;
    top: -50px;
    width: 100px;
    height: 100px;
    transform: translateX(-50%);
  }
  .upload_tip_title {
    font-size: 13px;
    color: red;
  }
  .upload_tip_text {
    margin-top: 16px;
    padding: 20px;
  }
  .upload_tip_p {
    margin-bottom: 4px;
    line-height: 1.5;
    color: #777;
  }
  .upload_tip_p::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 10px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #666;
  }
}
/deep/ .el-upload-list {
  position: relative;
  top: -30px;
}
.usages {
  margin: 10px 100px 30px 50px;
  display: flex;
  justify-content: space-between;
}
.usage {
  //   margin: 10px 50px 30px 50px;
  font-size: 18px;
  // color: rgb(138, 71, 247);
}
.scans {
  line-height: 30px;
  margin-bottom: 30px;
  .tit {
    font-size: 15px;

    color: #4099ff;
  }
  .files {
    color: #ff7c40;
  }
}
.sure {
  // line-height: 50px;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #406dff;
}
/deep/ .el-dialog__footer {
  text-align: center;
}
</style>